import styled from "styled-components";
import React from "react";
import { useHover } from "../hooks/hover";

export const Wrapper = styled.div`
  font-size: 1rem;
  color: ${({ theme, isActive }) =>
    isActive ? theme.primary : theme.secondary};
  cursor: pointer;
  margin-left: 1rem;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }

  @media (max-width: 650px) {
    font-size: 1.5rem;
    margin: 0;
    padding-bottom: 0.75rem;
  }
`;

export const NavItem = ({ isActive, name, children }) => {
  const hoverRef = useHover()[0];
  return (
    <Wrapper ref={hoverRef} isActive={isActive}>
      <div className="link">{name || children}</div>
    </Wrapper>
  );
};
