import { createGlobalStyle } from "styled-components";

// hsl(0,0%,65%)
export const theme = {
  primary: "#000000",
  text: "hsl(0,0%,20%)",
  secondary: "hsl(0,0%,65%)",
  light: "#D8D8D8",
  title: "#4A4A4A",
  spacer: "1rem",
  subheader: "#BFBFBF",
  background: "rgb(245, 245, 245)",
};

// TODO ant design styles are causing a lot of conflict
export const GlobalStyle = createGlobalStyle`     
  body {
    color: ${(props) => props.theme.text};
    background-color: ${(props) => props.theme.background}!important;
    font-size: 1rem!important;
    font-weight: 300;
    
    font-family: "Open Sans", sans-serif;
    font-family: 'Poppins', sans-serif;
  }
  
  .inner-wrapper {
    padding: 100px 0;
  }
  
  .page-header-wrapper {
    padding: 50px 0;
  }
  
  .colored-background {
    background: rgb(245, 245, 245);
  }
  
  .white-background {
    background: white;
  }
  
  .secondary {
    color: ${(props) => props.theme.secondary};
  }
  
  .inline {
    display: flex;
  }
  
  .pr-1 {
    padding-right: 0.5rem;
  }
  
  .pl-1 {
    padding-left: 0.5rem;
  }
  
  pre {
    padding: 8px 12px;
    overflow-x: auto;
  }
  
  code {
    color: #d14;
    background-color: #faf9f9;
    font-size: 14px;
    padding: 1px 5px;
  }
  
  table {
    margin-bottom: 30px;
    width: 100%;
    text-align: left;
    color: #3f3f3f;
    border-collapse: collapse;
    border: 1px solid hsl(0, 0%, 95%);
  }
  
  td {
    border: 1px solid hsl(0, 0%, 95%);
  }
  
  table th, table td {
    padding: 10px 15px;
  }
  
  table th {
    background-color: #fff;
    border: 1px solid hsl(0, 0%, 95%);
    border-bottom-color: hsl(0, 0%, 85%);
  }
  
  tr:nth-child(odd) {
    background-color: hsl(0, 0%, 98%);
  }
  
  .content-row {
    @media (max-width: 650px) {
      padding: 0 2rem;
    }
  }
  
  .snippet {
    margin-bottom: 1rem;
  }
  
  blockquote {
    margin: 0;
    padding: 15px;
    background: #eee;
    border-radius: 5px;
  }
`;
