import React, { useEffect, useState } from "react";
import { graphql, Link, useStaticQuery, withPrefix } from "gatsby";
import { useLocation } from "@reach/router";
import { snakeCase } from "lodash";
import { NavItem } from "./NavItem";
import styled, { css } from "styled-components";
import Twitter from "./Twitter";

const links = [
  // { name: "questions", path: "/questions/" },
  { name: "open source", path: "/open-source/" },
  { name: "podcast", path: "https://generallyintelligent.ai/podcast/" },
  // { name: "about", path: "/about/" },
];

const MAX_WIDTH_MOBILE_NAV = 700;

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  height: 6rem;
  align-items: center;
  transition: 0.3s;
  transition-timing-function: ease-in-out;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 99;

  @media (max-width: ${MAX_WIDTH_MOBILE_NAV}px) {
    padding: 0 2rem;
  }

  ${({ isFilled }) =>
    isFilled
      ? css`
          background: white;
          box-shadow: 0 2px 1px -1px hsl(0deg 0% 96%);
        `
      : ""}
`;

const Title = styled.div`
  font-size: 2rem;
  color: #008800;
`;

const SCROLL_THRESHOLD = 50;

const DesktopNav = styled.div`
  display: flex;
  @media (max-width: ${MAX_WIDTH_MOBILE_NAV}px) {
    display: none;
  }
`;

const MobileNav = styled.div`
  flex-direction: column;
  left: 0;
  position: fixed;
  right: 0;
  top: 4rem;
  z-index: 10000;
  bottom: 0;
  display: flex;
  height: 100%;
  width: 100%;
  background: white;
  padding: 1.5rem 2rem;
`;

const MobileNavToggle = styled.span`
  cursor: pointer;
  font-size: 30px;

  @media (min-width: ${MAX_WIDTH_MOBILE_NAV}px) {
    display: none !important;
  }
`;

export const Navigation = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const [isMobileNavVisible, setMobileNavVisible] = useState(false);
  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      // setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const location = useLocation();
  const data = useStaticQuery(
    graphql`
      query {
        site {
          pathPrefix
          siteMetadata {
            title
          }
        }
      }
    `
  );

  const isScrollingPastThreshold = scrollTop >= SCROLL_THRESHOLD;

  const navItems = links.map((link) => (
    <Link key={snakeCase(link.name)} to={link.path}>
      <NavItem
        isActive={location.pathname === withPrefix(link.path)}
        name={link.name}
      />
    </Link>
  ));

  navItems.push(
    <a key="twitter" href="https://twitter.com/joshalbrecht">
      <NavItem>
        <Twitter />
      </NavItem>
    </a>
  );

  return (
    <Nav isFilled={isScrollingPastThreshold}>
      <div className="title">
        <Link to={"/"}>
          <Title>{data.site.siteMetadata.title}</Title>
        </Link>
      </div>
      <MobileNavToggle
        onClick={() => setMobileNavVisible(!isMobileNavVisible)}
        className="material-icons"
      >
        {isMobileNavVisible ? "close" : "menu"}
      </MobileNavToggle>
      <DesktopNav>{navItems}</DesktopNav>
      {isMobileNavVisible && (
        <MobileNav onClick={() => setMobileNavVisible(false)}>
          {navItems}
        </MobileNav>
      )}
    </Nav>
  );
};
