import moment from "moment";

export const _filterDraftsFromPosts = (posts) => {
  return isDevelopment()
    ? posts
    : posts.filter((post) => {
        if (!post.fileAbsolutePath) {
          return false;
        }
        const filename = post.fileAbsolutePath.replace(/^.*[\\\/]/, "");
        return !filename.startsWith("_");
      });
};

export const getPostsFromQuery = (data) => {
  return _filterDraftsFromPosts(
    data.allMdx.edges.map((edge) => ({
      key: edge.node.fields.slug,
      excerpt: edge.node.excerpt,
      fileAbsolutePath: edge.node.fileAbsolutePath,
      ...edge.node.fields,
      ...edge.node.frontmatter,
    }))
  );
};

export const isDevelopment = () => {
  return process.env.NODE_ENV === "development";
};

export const isProduction = () => {
  return process.env.NODE_ENV === "development";
};

export const _parseLeverDescription = (description) => {
  // sigh ... this is very hacky and can break very easily
  return truncate(description.split("\n\n", 3)[1], 300);
};

export const getRolesFromQuery = (data) => {
  return data.allLever.edges.map((edge) => ({
    key: edge.node.id,
    description: _parseLeverDescription(edge.node.descriptionPlain),
    link: edge.node.hostedUrl,
    tags: [edge.node.categories.location, edge.node.categories.commitment],
    title: edge.node.text,
  }));
};

export const truncate = (input = "", length = 200) => {
  let stringIndex = 0;
  let actualIndex = 0;
  let isInTag = false;
  while (actualIndex < input.length) {
    if (input[actualIndex] === "<") {
      isInTag = true;
    }
    if (input[actualIndex] === ">") {
      isInTag = false;
    }
    actualIndex++;
    if (!isInTag) {
      stringIndex++;
    }
    if (stringIndex >= length && /[\s]/.test(input[actualIndex])) {
      break;
    }
  }
  let truncatedString = input.slice(0, actualIndex);
  if (stringIndex >= length) {
    truncatedString += "...";
  }
  return truncatedString;
};
export const columnProps = {
  xs: { offset: 0, span: 24 },
  sm: { offset: 2, span: 20 },
  md: { offset: 3, span: 18 },
  lg: { offset: 4, span: 16 },
  xl: { offset: 5, span: 14 },
  xxl: { offset: 6, span: 12 },
};

export const getReadableDate = (date) => {
  return moment(date.split(" ")[0], "YYYY-MM-DD").format("MMMM DD, YYYY");
};
