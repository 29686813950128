import "antd/dist/antd.css";
import React from "react";
import { GlobalStyle, theme } from "../styled";
import styled, { ThemeProvider } from "styled-components";
import { Col, Row } from "antd";
import { graphql, useStaticQuery } from "gatsby";
import { Navigation } from "./Navigation";
import { Helmet } from "react-helmet";

const Wrapper = styled.div`
  padding-top: 0rem;
`;

export default function Layout({ children }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `
  );

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.site.siteMetadata.title}</title>
        <meta
          name="title"
          content={data.site.siteMetadata.title}
          data-react-helmet="true"
        />
        <link rel="canonical" href={data.site.siteMetadata.siteUrl} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <meta
          name="viewport"
          content="height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <script>
          {`window.MathJax = {
              tex: {
                inlineMath: [['$', '$']]
              }
            }`}
        </script>
        <script
          type="text/javascript"
          src="https://polyfill.io/v3/polyfill.min.js?features=es6"
        />
        <script
          id="MathJax-script"
          type="text/javascript"
          async
          src="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js"
        />
      </Helmet>
      <GlobalStyle />
      <Wrapper>
        <Row justify={"center"}>
          <Col xs={{ offset: 0, span: 24 }}>{children}</Col>
        </Row>
      </Wrapper>
    </ThemeProvider>
  );
}
